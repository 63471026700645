import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './globalModule/login/login.component';
import { SharedModule } from './globalModule/shared.module';
import { HttpServiceInterceptor } from './globalModule/httpService.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogLevel } from 'msal';
import { MsalModule, MsalInterceptor } from '../../node_modules/@azure/msal-angular';
import { environment } from '../environments/environment';
import { MonitoringErrorHandler } from './error.handling';
import { OrderByQltPipe } from './globalModule/sorting.pipe';
import { DatePipe } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';


export function loggerCallback(logLevel, message, piiEnabled) {
  // console.log(message);
  console.log('msal logLevel', logLevel);
  console.log('msal LogLevel', LogLevel);
  console.log('msal LogLevel.Verbose', LogLevel.Verbose);
  console.log('msal Warning', LogLevel.Warning);
  console.log('msal Error', LogLevel.Error);
  console.log('msal Info', LogLevel.Info);
  console.log('msal message', message);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrderByQltPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 1000, // 15 seconds
      progressBar: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    MsalModule.forRoot({
      clientID: environment.clientID,
      authority: environment.authority,
      validateAuthority: true,
      redirectUri: environment.redirectURi,
      cacheLocation: 'localStorage', // 'sessionStorage',
      postLogoutRedirectUri: environment.redirectURi,
      navigateToLoginRequestUrl: true,
      logger: loggerCallback,
      isAngular: true,
      consentScopes: environment.scopes,
      correlationId: '1234',
      level: LogLevel.Verbose,
      piiLoggingEnabled: true,
    }
    ),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpServiceInterceptor,
    multi: true,
  },
    DatePipe,
  {
    provide: ErrorHandler,
    useClass: MonitoringErrorHandler
  },
    CookieService
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
